<div class="my-login-page">
	<section class="h-100">
		<div class="container h-100">
			<div class="row justify-content-md-center h-100">
				<div class="card-wrapper">
					<div class="brand">
						<img src="assets/img/login2.png" alt="logo">
					</div>
					<div class="card fat" style="background-color:rgba(23, 119, 4, 0.41);">
						<div class="card-body">
							<h4 class="card-title text-center">Please Login</h4>

							<form #form='ngForm' class="form-signin" autocomplete="off" (ngSubmit)="onSubmit(form)">

								<div class="form-group">
									<label for="email">Username</label>
									<input id="email" type="email" class="form-control" #UserName="ngModel"
										name="UserName" [(ngModel)]="formModel.UserName" value="" required autofocus>
									<div class="invalid-feedback">
										Username is invalid
									</div>
								</div>
								<br>
								<div class="form-group">
									<label for="password">Password</label>
									<input id="password" type="password" class="form-control" #PasswordHash="ngModel"
										name="PasswordHash" [(ngModel)]="formModel.PasswordHash" required data-eye>
									<div class="invalid-feedback">
										Password is required
									</div>
								</div>
								<br><br>
								<div class="text-center">
									<button type="submit" class="btn btn-success btn-lg" [disabled]="form.invalid">Log
										In</button>
									<br>
									<a href="https://iotsystem.register.braceinnovations.co.za/elearning"
										role="button">Sign Up if not registered already</a>
								</div>





							</form>
						</div>
					</div>
					<div class="footer">
						Copyright &copy; 2020 &mdash; Brace Holdings Innovations
					</div>
				</div>
			</div>
		</div>
	</section>

</div>