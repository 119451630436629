import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/data.service' ;
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-logquery',
  templateUrl: './logquery.component.html',
  styleUrls: ['./logquery.component.css']
})
export class LogqueryComponent implements OnInit {

  constructor(private httpClient: HttpClient,private toastr: ToastrService, public service: DataService,public router: Router) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.service.logEnquiry().subscribe(
      (res: any) => {
          this.router.navigateByUrl('/home');
      },
      err => {
        if (err.status == 400)
          this.toastr.error('Course Error', 'Error');
        else
          console.log(err);
      }
    );
  }


}
