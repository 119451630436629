<div role="main" class="container">
<div class="d-flex align-items-center p-3 my-3 text-white-50 bg-purple rounded shadow-sm d-flex justify-content-center">
  <div class="lh-100">
    <h2 class="mb-0 text-white lh-1">WiFi Data Usage Report</h2>
  </div>
</div>
<h5 class="d-flex justify-content-center"  style="color: white;">Data Usage Per Region or Hotspot</h5>
<canvas id="chartRegion" width="80" height="30"></canvas>
<hr style="color: white;">
<div class="row mb-2" >
  <div class="col-md-6" id="canvas-holder" style="width:50%">
    <canvas id="chartGender"></canvas>
  </div>
  <div class="col-md-6" id="canvas-holder" style="width:50%">
    <canvas id="chartCategory"></canvas>
  </div>
</div>

</div>
