import { Component, OnInit } from '@angular/core';
import {DataService} from '../../services/data.service';
import {UserService} from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import 'rxjs/Rx' ;




declare var require: any
const FileSaver = require('file-saver');

declare var sortTable : any ;

@Component({
  selector: 'app-siteupdates',
  templateUrl: './siteupdates.component.html',
  styleUrls: ['./siteupdates.component.css']
})
export class SiteupdatesComponent implements OnInit {

  constructor(private service : DataService,private userService: UserService,
    private httpClient: HttpClient,private toastr: ToastrService,public router: Router) { }

  reports ;
  userId ;
  hotspots ;
  selection = ["Yes","No"]

 

  ngOnInit(): void {
   
    this.service.sendReportform.reset();
   
    var payLoad =  JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    var user = payLoad.UserID;
    this.userId = user ;
  
    this.service.GetReports().subscribe(
      res => {
        this.reports = res ;
        sortTable();
       // console.log(res);
      },
      err => {
        console.log(err);
      },
    );

   
    
    

    this.service.GetHotspotNames().subscribe(
      res => {
        this.hotspots = res ;
      },
      err => {
        console.log(err);
      },
    );

    
  }

  userStatus(user:string):any{
    return this.userService.SearchRole(user) ;
  }

  onSubmit() {
  
    
    this.service.sendReport(this.userId).subscribe(
      (res: any) => {
        this.service.sendReportform.reset();
        this.router.navigateByUrl('/home');
      },
      err => {
        if (err.status == 400)
          this.toastr.error('Course Error', 'Error');
        else
          console.log(err);
      }
    );
  }


  getReport(){
    var filename : string ;
    let dateTime = new Date() ;
    filename = "Site Operations Update" + dateTime ; 
 
    this.service.ExtractReports()
    .subscribe(
      (data: Blob) => {
        var file = new Blob([data], { type: 'application/vnd.ms-excel' })
        var fileURL = URL.createObjectURL(file);

       // if you want to open PDF in new tab
       //window.open(fileURL); 
        var a         = document.createElement('a');
        a.href        = fileURL; 
        a.target      = '_blank';
        a.download    = filename+'.xlsx';
        document.body.appendChild(a);
        a.click();
      },
      (error) => {
        console.log('getPDF error: ',error);
      }
    );
  }

}
