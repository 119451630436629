<app-header></app-header>

<div class="container-fluid" >
  <div class="row">
    <app-navbar></app-navbar>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="container">
        <main role="main" class="pb-3">
          <!-------------------------------------------------------------------------------------------------------------->

          <h2 class="text-center">Live Active Conections</h2>
          <div class="table-responsive">
            <table class="table table-striped table-sm" >
              <thead>
                <tr>
                  <th>IP Address</th>
                  <th>Username</th>
                  <th>Logged Time</th>
                  <th>Hotspot Connected</th>
                  <th>Total Data Consumption</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let info of activeusers"  >
                  <td>{{ info.address }}</td>
                  <td>{{ info.user }}</td>
                  <td>{{ info.uptime}}</td>
                  <td>{{ info.server}}</td>
                  <td>{{ info.bytesout  | number : '1.2-2'}} MB</td>
                  <!--<td><a ><i class="fas fa-eye"></i></a></td>-->
               </tr>
              </tbody>
            </table>
          </div>


          <!-------------------------------------------------------------------------------------------------------------->
        </main>

      </div>
    </main>
  </div>

</div>
