<app-header></app-header>
<div class="container-fluid">
  <div class="row">
    <app-navbar></app-navbar>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="container">
        <main role="main" class="pb-3">
          <!-------------------------------------------------------------------------------------------------------------->
          <div *ngIf="userStatus('Admin')">
            <div
              class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 class="h2">Site Operation Updates</h1>
              <div class="btn-toolbar mb-2 mb-md-0">
                <div class="btn-group me-2">
                  <button (click)="getReport()" type="button" class="btn btn-sm btn-outline-secondary"
                    *ngIf="userStatus('Admin')">Export /
                    Download</button>
                </div>
              </div>
            </div>
            
          <br>
              <div class="container ">
                <table  class="table table-striped table-bordered" cellspacing="0" width="100%" id="sort">
                  <thead>
                    <tr>
                      <th>Report Upload Date</th>
                      <th>Name of Hotspot</th>
                      <th>Issue Discription</th>
                      <th>Challenge Identified</th>
                      <th>Proposed Resolution</th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr *ngFor="let info of reports">
                      <td>{{ info.reportUploadDate}}</td>
                      <td>{{ info.nameofHotspot }}</td>
                      <td>{{ info.issueDiscription }}</td>
                      <td>{{ info.challengeIdentified}}</td>
                      <td>{{ info.proposedResolution}}</td>                 
                      <!--<td><a ><i class="fas fa-eye"></i></a></td>-->
                    </tr>
                  </tbody>
                </table>

              </div>
          </div>
          <!-------------------------------------------------------------------------------------------------------------->

          <div *ngIf="userStatus('User')">
            <hr>
            <hr>
            <form [formGroup]="service.sendReportform" autocomplete="off" (submit)="onSubmit()">
              <div class="row">

                <div class="form-group col-md-2">
                  <label>Date</label>
                  <input type="date" class="form-control" formControlName="date">
                  <label class="text-danger"
                    *ngIf="service.sendReportform.get('date').touched && service.sendReportform.get('date').errors?.required">This
                    field is mandatory.</label>
                </div>

                <div class="form-group col-md-3">
                  <label>Name of Hotspot</label>
                  <select class="form-select" formControlName="nameofHotspot">
                    <option selected>Select</option>
                    <option *ngFor="let info of hotspots">
                      {{info.nameofHotspot}}
                    </option>
                  </select>
                  <label class="text-danger"
                    *ngIf="service.sendReportform.get('nameofHotspot').touched && service.sendReportform.get('nameofHotspot').errors?.required">This
                    field is mandatory.</label>
                </div>

                <div class="form-group col-md-7">
                  <label>Issue Discription</label>
                  <input type="text" class="form-control" formControlName="issueDiscription">
                  <label class="text-danger"
                    *ngIf="service.sendReportform.get('issueDiscription').touched && service.sendReportform.get('issueDiscription').errors?.required">This
                    field is mandatory.</label>
                </div>


                <div class="form-group col-md-12">
                  <label>Challenge Identified</label>
                  <textarea rows="6" class="form-control" formControlName="challengeIdentified"></textarea>
                  <label class="text-danger"
                    *ngIf="service.sendReportform.get('challengeIdentified').touched && service.sendReportform.get('challengeIdentified').errors?.required">This
                    field is mandatory.</label>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-3">
                  <label>Complaint Owner</label>
                  <input type="text" class="form-control" formControlName="complaintOwner">
                  <label class="text-danger"
                    *ngIf="service.sendReportform.get('complaintOwner').touched && service.sendReportform.get('complaintOwner').errors?.required">This
                    field is mandatory.</label>
                </div>

                <div class="form-group col-md-9">
                  <label>Proposed Resolution</label>
                  <input type="text" class="form-control" formControlName="proposedResolution">
                  <label class="text-danger"
                    *ngIf="service.sendReportform.get('proposedResolution').touched && service.sendReportform.get('proposedResolution').errors?.required">This
                    field is mandatory.</label>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-2">
                  <label>Date Resolved</label>
                  <input type="text" class="form-control" formControlName="dateResolved">
                  <label class="text-danger"
                    *ngIf="service.sendReportform.get('dateResolved').touched && service.sendReportform.get('dateResolved').errors?.required">This
                    field is mandatory.</label>
                </div>

                <div class="form-group col-md-2">
                  <label>Date Closed</label>
                  <input type="text" class="form-control" formControlName="dateClosed">
                  <label class="text-danger"
                    *ngIf="service.sendReportform.get('dateClosed').touched && service.sendReportform.get('dateClosed').errors?.required">This
                    field is mandatory.</label>
                </div>

                <div class="form-group col-md-2">
                  <label>Satisfied with Process</label>
                  <select class="form-select" formControlName="satisfiedwithProcess">
                    <option selected>Select</option>
                    <option *ngFor="let info of selection">
                      {{info}}
                    </option>
                  </select>
                  <label class="text-danger"
                    *ngIf="service.sendReportform.get('satisfiedwithProcess').touched && service.sendReportform.get('satisfiedwithProcess').errors?.required">This
                    field is mandatory.</label>
                </div>

                <div class="form-group col-md-6">
                  <label>If no, why not ?</label>
                  <input type="text" class="form-control" formControlName="notSatisfiedwithProcess">
                  <label class="text-danger"
                    *ngIf="service.sendReportform.get('notSatisfiedwithProcess').touched && service.sendReportform.get('notSatisfiedwithProcess').errors?.required">This
                    field is mandatory.</label>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-2">
                  <label>Satisfied with Outcome</label>
                  <select class="form-select" formControlName="satisfiedwithOutcome">
                    <option selected>Select</option>
                    <option *ngFor="let info of selection">
                      {{info}}
                    </option>
                  </select>
                  <label class="text-danger"
                    *ngIf="service.sendReportform.get('satisfiedwithOutcome').touched && service.sendReportform.get('satisfiedwithOutcome').errors?.required">This
                    field is mandatory.</label>
                </div>

                <div class="form-group col-md-10">
                  <label>If no, why not ?</label>
                  <input type="text" class="form-control" formControlName="notSatisfiedwithOutcome">
                  <label class="text-danger"
                    *ngIf="service.sendReportform.get('notSatisfiedwithOutcome').touched && service.sendReportform.get('notSatisfiedwithOutcome').errors?.required">This
                    field is mandatory.</label>
                </div>

              </div>

              <div class="row">
                <div class="form-group col-md-12">
                  <label>Comments</label>
                  <textarea rows="3" class="form-control" formControlName="comments"></textarea>
                  <label class="text-danger"
                    *ngIf="service.sendReportform.get('comments').touched && service.sendReportform.get('comments').errors?.required">This
                    field is mandatory.</label>
                </div>
              </div>

              <hr>
              <div class="text-center">
                <div class="form-group col-md-8 offset-md-2">

                  <button type="button" class="btn btn-lg btn-danger" style="margin:10px;"
                    (click)="router.navigate(['/home']);">Cancel</button>
                  <button type="submit" class="btn btn-lg btn-primary" [disabled]="!service.sendReportform.valid">Submit
                    Report</button>

                </div>
              </div>
            </form>

          </div>

        </main>

      </div>
    </main>
  </div>

</div>

