<app-header></app-header>


  <div class="row">
    <app-navbar></app-navbar>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">

      <hr><hr>
      <hr style="color:black;"><hr>
      <hr style="color: blue"><hr>

      <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="card card-margin">
                    <div class="card-body pt-0">
                        <div class="widget-49">
                          <div>
                            <div class="card-box bg-blue">
                                <div class="inner">
                                    <h1>Microsoft Learn</h1>
                                    <h6>This contains the modules and assessments prepared by Microsoft
                                      for the sole reason of undestading and mastering microsoft products </h6>
                                </div>
                                <div class="icon">
                                    <i class="fa fa-money" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                        <div class="widget-49-meeting-action">
                            <a routerLinkActive="active" routerLink="/catelogProducts"  class="btn btn-sm btn-flash-border-warning">View All</a>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
              <div class="card card-margin">
                  <div class="card-body pt-0">
                      <div class="widget-49">
                        <div>
                          <div class="card-box bg-green">
                              <div class="inner">
                                  <h1>Internal LMS</h1>
                                  <h6>This contains the modules and assessments prepared by Brace Holdings
                                    in order to give the community general knowledge regarding Techology and Internet </h6>
                              </div>
                              <div class="icon">
                                  <i class="fa fa-money" aria-hidden="true"></i>
                              </div>
                          </div>
                      </div>
                      <div class="widget-49-meeting-action">
                          <a routerLinkActive="active" routerLink="/otherCourses" class="btn btn-sm btn-flash-border-warning">View All</a>
                      </div>
                      </div>
                  </div>
              </div>
          </div>





        </div>
        </div>

    </main>
  </div>


