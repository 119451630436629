import { Component, OnInit,ViewChild } from '@angular/core';
import { DataService } from '../../services/data.service' ;
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-addresource',
  templateUrl: './addresource.component.html',
  styleUrls: ['./addresource.component.css']
})
export class AddresourceComponent implements OnInit {

  nativeElement: HTMLInputElement ;

  @ViewChild('fileInput') fileInput:any;

  constructor(public service: DataService,private toastr: ToastrService,public router: Router) { }

  ngOnInit(): void {
    this.service.resourceform.reset();
  }

  get getForm(){
    return this.service.resourceform.controls;
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.service.resourceform.patchValue({
        fileSource: file
      });
    }
  }

  Submit(){
    const formData = new FormData();
    formData.append('file', this.service.resourceform.get('fileSource').value);
    this.service.addResource(formData).subscribe(
      (res: any) => {
        this.service.resourceform.reset();
          this.router.navigateByUrl('/resources');
      },
      err => {
        if (err.status == 400)
          this.toastr.error('Upload Error', 'Error');
        else
          console.log(err);
      }
    );

  }

  /*getFile() {
    this.nativeElement = this.fileInput.nativeElement;
  }

  Submit(){
    //console.log(this.nativeElement.files);
    var file = this.nativeElement.files ;
    this.service.uploadFile(file).subscribe(
      (res: any) => {
        if (res) {
          this.router.navigateByUrl('/courses');
        }
      },
      err => {
        if (err.status == 400)
          this.toastr.error('Upload Error', 'Error');
        else
          console.log(err);
      }
    );
  }*/

}
