import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpClient,HttpHeaders } from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private fb: FormBuilder, private http: HttpClient) { }
  //readonly BaseURI  ="https://dev.braceinnovations.co.za/api/";
  readonly BaseURI = "http://18.132.98.36:81/api/" ;

  login(formData) {
    return this.http.post(this.BaseURI + 'user/login', formData,) ;
  }

  SearchRole(role :string): boolean {
    var isMatch = false;
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    var userRole = payLoad.role;
      if (userRole === role) {
        isMatch = true;
      }
    return isMatch;
  }

}
