import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service' ;
import { Router } from '@angular/router';

@Component({
  selector: 'app-catelogproducts',
  templateUrl: './catelogproducts.component.html',
  styleUrls: ['./catelogproducts.component.css']
})
export class CatelogproductsComponent implements OnInit {

  constructor(private service: DataService,public router: Router) { }
  products ;

  ngOnInit(): void {
    this.service.MicrosoftLearnCatalogProducts().subscribe(
      res => {
        this.products = res;
        //console.log(res);
      },
      err => {
        console.log(err);
      },
    );
  }

  productSelected(productId:string) {
   // console.log(productId);
    this.service.changeProduct(productId);
    this.router.navigateByUrl('/microsoftLearn');
  }

}
