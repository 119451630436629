import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service' ;
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';


@Component({
  selector: 'app-addcourse',
  templateUrl: './addcourse.component.html',
  styleUrls: ['./addcourse.component.css']
})
export class AddcourseComponent implements OnInit {

  constructor(public service: DataService,private toastr: ToastrService,public router: Router) { }

  ngOnInit(): void {
    this.service.courseform.reset();
  }

  onSubmit() {
    this.service.addCourse().subscribe(
      (res: any) => {

          this.router.navigateByUrl('/courses');

      },
      err => {
        if (err.status == 400)
          this.toastr.error('Course Error', 'Error');
        else
          console.log(err);
      }
    );
  }

}
