import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service' ;

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {

  courses ;

  constructor( private service: DataService) { }

  ngOnInit(): void {
    this.service.GetLMSProperties("c").subscribe(
      res => {
        this.courses = res;
      },
      err => {
        console.log(err);
      },
    );
  }

}
