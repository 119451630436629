import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { RouterModule } from '@angular/router';
import {DashboardModule} from './dashboard/dashboard.module';
import { LoginComponent } from './login/login.component';
import { UserService } from './services/user.service';
import {DataService} from './services/data.service'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AuthInterceptor } from './helpers/auth.interceptor';
import { RegisterationComponent } from './registeration/registeration.component';
import { GeneralReportComponent } from './general-report/general-report.component';
import { DatePipe } from '@angular/common';

import { DataTablesModule } from "angular-datatables";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterationComponent,
    GeneralReportComponent,
  ],
  imports: [
    DataTablesModule,
    BrowserModule,
    AppRoutingModule,
    DashboardModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DashboardModule,
    DataTablesModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      progressBar: true
    }),

  ],
  providers:[DatePipe,UserService,DataService,{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
