<app-header></app-header>

<div class="container-fluid">
  <div class="row">
    <app-navbar></app-navbar>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="container">
        <main role="main" class="pb-3 ">
        <!-------------------------------------------------------------------------------------------------------------->

        <section class="section services-section" id="services">
          <div class="container">
              <div class="row">

               
                  <div class="col-sm-6 col-lg-4" *ngFor="let info of courses">
                      <div class="feature-box-1" routerLinkActive="active" (click)= "courseSelected(info.courseId)" >
                          <div class="icon">
                              <i class="fa fa-th"></i>
                          </div>
                          <div class="feature-content">
                              <h5>{{info.courseId}} : {{info.courseName}}</h5>
                              <p>{{info.description}}</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>

        <!-------------------------------------------------------------------------------------------------------------->
        </main>

      </div>
    </main>
  </div>

</div>
