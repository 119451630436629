<app-header></app-header>

<div class="container-fluid">
  <div class="row">
    <app-navbar></app-navbar>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="container">
        <main role="main" class="pb-3 ">
        <!-------------------------------------------------------------------------------------------------------------->
        <div  *ngIf="userStatus('Stuff')">
        <div class="table-responsive">
          <div class="table-wrapper">
              <div class="table-title">
                  <div class="row">
                      <div class="col-sm-8"><h2><b></b></h2></div>
                      <div class="col-sm-4">
                          <button type="button" class="btn btn-info add-new" [routerLink]="['/addresource']"><i class="fa fa-plus"   ></i> Add Resource</button>
                      </div>
                  </div>
              </div>
              <table class="table table-bordered">
                  <thead>
                      <tr>
                          <th>Resource Code</th>
                          <th>Resource Name</th>
                          <th>Description</th>
                          <th></th>

                      </tr>
                  </thead>
                  <tbody>

                      <tr *ngFor="let info of resources">

                        <td>{{ info.resourceCode }}</td>
                        <td>{{ info.resourceName}}</td>
                        <td>{{ info.description}}</td>
                        <td>
                          <a ><i class="fas fa-user-edit"></i></a>
                          <a ><i class="fa fa-trash-alt" ></i></a>
                        </td>
                     </tr>

                  </tbody>
              </table>
          </div>
      </div>
    </div>

    <div  *ngIf="userStatus('Student')">
      <div class="table-responsive">
        <div class="table-wrapper">
            <div class="table-title">
                <div class="row">
                    <div class="col-sm-8"><h2><b></b></h2></div>
                    <div class="col-sm-4">
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="card-box">
                        <div class="row">
                            <div class="col-lg-6 col-xl-6">
                                <h4 class="header-title m-b-30">Study Materials</h4>
                            </div>
                        </div>

                        <div class="row" >
                            <div class="col-lg-3 col-xl-2"  *ngFor="let info of resources">
                                <div class="file-man-box">
                                    <div class="file-img-box"><img  src="../../../assets/img/mov-file.svg" alt="icon"></div><a (click)="Download(info.resourceName)"  class="file-download"><i class="fa fa-download"></i></a>
                                    <div class="file-man-title">
                                        <h5 class="mb-0 text-overflow">{{ info.description}}</h5>
                                        <p class="mb-0"><small>{{file(info.resourceName)}}</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                       

                    </div>
                </div>
            </div>
        </div>
    </div>

    </div>
        <!-------------------------------------------------------------------------------------------------------------->
        </main>

      </div>
    </main>
  </div>

</div>
