<app-header></app-header>

<div class="container-fluid">
  <div class="row">
    <app-navbar></app-navbar>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="container">
        <main role="main" class="pb-3 ">
        <!-------------------------------------------------------------------------------------------------------------->

        <div *ngIf="userStatus('Student')">
          <section class="timeline_area section_padding_130" >
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-sm-8 col-lg-6">
                        <!-- Section Heading-->
                        <div class="section_heading text-center">
                            <h6>Calender</h6>
                            <h3>Annoucement and Events Updates</h3>
                            <div class="line"></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <!-- Timeline Area-->
                        <div class="apland-timeline-area">
                            <!-- Single Timeline Content-->
                            <div class="single-timeline-area" >
                                <div class="timeline-date wow fadeInLeft" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInLeft;"></div>
                                <div class="row">
                                    <div class="col-12 col-md-6 col-lg-4"  *ngFor="let info of events">
                                        <div class="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                                            <div class="timeline-icon"><i class="fa fa-address-card" aria-hidden="true"></i></div>
                                            <div class="timeline-text">
                                                <h6>{{info.meetingPurpose}}</h6>
                                                <p>Date : {{info.venue}}</p>
                                                <p>Venue : {{info.date}}</p>
                                                <p>Time : {{info.time}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </div>

        <div *ngIf="userStatus('Stuff')">
            <section class="timeline_area section_padding_130" >
              <div class="container">
                  <div class="row justify-content-center">
                      <div class="col-12 col-sm-8 col-lg-6">
                          <!-- Section Heading-->
                          <div class="section_heading text-center">
                              <h6>Calender</h6>
                              <h3>Annoucement and Events Updates</h3>
                              <div class="line"></div>
                          </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-12">
                          <!-- Timeline Area-->
                          <div class="apland-timeline-area">
                              <!-- Single Timeline Content-->
                              <div class="single-timeline-area" >
                                  <div class="timeline-date wow fadeInLeft" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInLeft;"></div>
                                  <div class="row">
                                      <div class="col-12 col-md-6 col-lg-4"  *ngFor="let info of events">
                                          <div class="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                                              <div class="timeline-icon"><i class="fa fa-address-card" aria-hidden="true"></i></div>
                                              <div class="timeline-text">
                                                  <h6>{{info.meetingPurpose}}</h6>
                                                  <p>Date : {{info.venue}}</p>
                                                  <p>Venue : {{info.date}}</p>
                                                  <p>Time : {{info.time}}</p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          </div>

        <!-------------------------------------------------------------------------------------------------------------->
        </main>

      </div>
    </main>
  </div>

</div>
