import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service' ;

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  constructor( private service: DataService) { }
  events ;

  ngOnInit(): void {
    this.service.GetLMSProperties("e").subscribe(
      res =>  {
        this.events = res;
        //console.log(this.resources);
      },
      err => {
        console.log(err);
      },
    );
  }

  userStatus(user: string): any {
    return this.service.SearchRole(user);
  }

}
