<app-header></app-header>

<div class="container-fluid">
  <div class="row">
    <app-navbar></app-navbar>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="container">
        <main role="main" class="pb-3 ">
        <!-------------------------------------------------------------------------------------------------------------->

        <div class="table-responsive">
          <div class="table-wrapper">
              <div class="table-title">
                  <div class="row">
                      <div class="col-sm-8"><h2><b></b></h2></div>
                  </div>
              </div>

          </div>
      </div>


      <form  [formGroup]="service.resourceform" (ngSubmit)="Submit()">


        <div class="row">

        <div class="form-group col-md-6">
            <label for="name">Course Code</label>
            <input formControlName="courseCode" id="courseCode" type="text" class="form-control">
        </div>

        <div class="form-group col-md-6">
          <label for="name">Resource Code</label>
          <input formControlName="resourceCode" id="resourceCode" type="text" class="form-control">
        </div>

        <div class="form-group col-md-6">
          <label for="name">Description</label>
          <input formControlName="description" id="description" type="text" class="form-control">
        </div>

        <div class="form-group col-md-6">
            <label for="file">File</label>
            <input formControlName="file" id="file" type="file" class="form-control"(change)="onFileChange($event)">
        </div>

        <div class="text-center">
          <div class="form-group col-md-8 offset-md-2">

          <button type="button" class="btn btn-lg btn-danger" style="margin:10px;" (click)="router.navigate(['/resources']);" >Cancel</button>
          <button type="submit" class="btn btn-lg btn-primary" [disabled]="!service.resourceform.valid">Add Resource</button>

          </div>
        </div>

      </div>
    </form>

        <!-------------------------------------------------------------------------------------------------------------->
    </main>

      </div>
    </main>
  </div>
</div>
