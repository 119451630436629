<app-header></app-header>

<div class="container-fluid">
  <div class="row">
    <app-navbar></app-navbar>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="container">
        <main role="main" class="pb-3 ">
        <!-------------------------------------------------------------------------------------------------------------->

        <div class="table-responsive">
          <div class="table-wrapper">
              <div class="table-title">
                  <div class="row">
                      <div class="col-sm-8"><h2><b></b></h2></div>
                      <div class="col-sm-4">
                          <button type="button" class="btn btn-info add-new" [routerLink]="['/addcourse']"><i class="fa fa-plus"   ></i> Add Course</button>
                      </div>
                  </div>
              </div>
              <table class="table table-bordered">
                  <thead>
                      <tr>
                          <th>Course ID</th>
                          <th>Course Name</th>
                          <th>Course Description</th>
                          <th></th>

                      </tr>
                  </thead>
                  <tbody>

                      <tr *ngFor="let info of courses">

                        <td>{{ info.courseId }}</td>
                        <td>{{ info.courseName}}</td>
                        <td>{{ info.description}}</td>
                        <td>
                          <a ><i class="fas fa-user-edit"></i></a>
                          <a ><i class="fa fa-trash-alt" ></i></a>
                        </td>
                     </tr>

                  </tbody>
              </table>
          </div>
      </div>

        <!-------------------------------------------------------------------------------------------------------------->
        </main>

      </div>
    </main>
  </div>

</div>
