import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service' ;
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-microsoftlearn',
  templateUrl: './microsoftlearn.component.html',
  styleUrls: ['./microsoftlearn.component.css']
})
export class MicrosoftlearnComponent implements OnInit {

  constructor(private service: DataService,public router: Router) { }
  modules ;
  subscription: Subscription;
  selectedProduct;
  StoredselectedProduct:string ;

  ngOnInit(): void {

    this.subscription = this.service.productMessage.subscribe(data => this.selectedProduct = data) ;

   if (this.selectedProduct != 'default product'){
    localStorage.setItem('product', this.selectedProduct);
        this.StoredselectedProduct = this.selectedProduct;
    }

    if (this.selectedProduct == 'default product'){
          this.StoredselectedProduct = localStorage.getItem('product');
      }

      this.LoadModules(this.StoredselectedProduct);
  }

  LoadModules(productId:string){
    this.service.MicrosoftLearnCatalog(productId).subscribe(
      res => {
        this.modules = res;
        //console.log(res);
      },
      err => {
        console.log(err);
      },
    );
  }

  courseSelected(courseUrl:string) {
    window.open(courseUrl, "_blank");
  }


}
