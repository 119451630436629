<app-header></app-header>

<div class="container-fluid">
  <div class="row">
    <app-navbar></app-navbar>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="container">
        <main role="main" class="pb-3 ">
        <!-------------------------------------------------------------------------------------------------------------->

        <div class="text-center">
          <hr style="color: white;">
          <hr>
        </div>
        <section class="section services-section" id="services">
          <div class="container">
              <div class="row">
                  <div class="col-lg-6">
                      <div class="section-title">
                          <h2 class="center">Course Material</h2>
                      </div>
                  </div>
              </div>
              <div class="row" >
                <div class="col-12">
                  <div class="card-box">
                      <div class="row">
                          <div class="col-lg-6 col-xl-6">
                              <h4 class="header-title m-b-30">Study Materials</h4>
                          </div>
                      </div>

                      <div class="row" >
                          <div class="col-lg-3 col-xl-2"  *ngFor="let info of resources">
                              <div class="file-man-box">
                                  <div class="file-img-box"><img  src="../../../assets/img/mov-file.svg" alt="icon"></div><a (click)="Download(info.resourceName)"  class="file-download"><i class="fa fa-download"></i></a>
                                  <div class="file-man-title">
                                      <h5 class="mb-0 text-overflow">{{ info.description}}</h5>
                                      <p class="mb-0"><small>{{file(info.resourceName)}}</small></p>
                                  </div>
                              </div>
                          </div>
                      </div>

                  </div>
              </div>
              </div>



              
          </div>
      </section>
            
      <button style="float: right;" type="submit" class="btn btn-lg btn-primary">Ready for Assesment</button>

        <!-------------------------------------------------------------------------------------------------------------->
        </main>

      </div>
    </main>
  </div>

</div>
