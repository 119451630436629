<app-header></app-header>

<div class="container-fluid">
  <div class="row">
    <app-navbar></app-navbar>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="container">
        <main role="main" class="pb-3 ">
        <!-------------------------------------------------------------------------------------------------------------->
        <div  *ngIf="userStatus('Stuff')">
          <div class="row">
            <div class="col-lg-3 col-md-8 h-100" >
              <div class="circle-tile ">
                <a  routerLinkActive="active" routerLink="/courses"><div class="circle-tile-heading dark-blue"><i class="fa fa-graduation-cap fa-fw fa-3x"></i></div></a>
                <div class="circle-tile-content dark-blue">
                  <div class="circle-tile-description text-faded"><h1>Courses</h1></div>
                  <div class="circle-tile-number text-faded "></div>
                  <a class="circle-tile-footer" href="#"><i class="fa fa-chevron-circle-right"></i></a>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-sm-8">
              <div class="circle-tile ">
                <a  routerLinkActive="active" routerLink="/assignments"><div class="circle-tile-heading blue"><i class="fa fa-check fa-fw fa-3x"></i></div></a>
                <div class="circle-tile-content blue">
                  <div class="circle-tile-description text-faded"><h1>Assessments</h1></div>
                  <div class="circle-tile-number text-faded "></div>
                  <a class="circle-tile-footer" href="#"><i class="fa fa-chevron-circle-right"></i></a>
                </div>
              </div>
            </div>

          <div class="col-lg-3 col-sm-8">
              <div class="circle-tile ">
                <a  routerLinkActive="active" routerLink="/resources"><div class="circle-tile-heading green"><i class="fa fa-briefcase fa-fw fa-3x"></i></div></a>
                <div class="circle-tile-content green">
                  <div class="circle-tile-description text-faded"><h1>Resources</h1></div>
                  <div class="circle-tile-number text-faded "></div>
                  <a class="circle-tile-footer" href="#"><i class="fa fa-chevron-circle-right"></i></a>
                </div>
              </div>
            </div>

          <div class="col-lg-3 col-sm-8">
              <div class="circle-tile ">
                <a href="#"><div class="circle-tile-heading orange"><i class="fa fa-star fa-fw fa-3x"></i></div></a>
                <div class="circle-tile-content orange">
                  <div class="circle-tile-description text-faded"><h1>Ratings</h1></div>
                  <div class="circle-tile-number text-faded "></div>
                  <a class="circle-tile-footer" href="#"><i class="fa fa-chevron-circle-right"></i></a>
                </div>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-lg-3 col-md-8 h-100">
              <div class="circle-tile " >
                <a routerLinkActive="active" routerLink="/events"><div class="circle-tile-heading red"><i class="fa fa-bullhorn fa-fw fa-3x"></i></div></a>
                <div class="circle-tile-content red">
                  <div class="circle-tile-description text-faded"><h1>Events</h1></div>
                  <div class="circle-tile-number text-faded "></div>
                  <a class="circle-tile-footer" href="#"><i class="fa fa-chevron-circle-right"></i></a>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-sm-8">
              <div class="circle-tile ">
                <a href="#"><div class="circle-tile-heading purple"><i class="fa fa-trophy fa-fw fa-3x"></i></div></a>
                <div class="circle-tile-content purple">
                  <div class="circle-tile-description text-faded"><h1>Awards</h1></div>
                  <div class="circle-tile-number text-faded "></div>
                  <a class="circle-tile-footer" href="#"><i class="fa fa-chevron-circle-right"></i></a>
                </div>
              </div>
            </div>

          <div class="col-lg-3 col-sm-8">
              <div class="circle-tile ">
                <a href="#"><div class="circle-tile-heading gray"><i class="fa fa-university fa-fw fa-3x"></i></div></a>
                <div class="circle-tile-content gray">
                  <div class="circle-tile-description text-faded"><h1>Virtual Class</h1></div>
                  <div class="circle-tile-number text-faded "></div>
                  <a class="circle-tile-footer" href="#"><i class="fa fa-chevron-circle-right"></i></a>
                </div>
              </div>
            </div>

          <div class="col-lg-3 col-sm-8">
              <div class="circle-tile ">
                <a href="#"><div class="circle-tile-heading blue"><i class="fa fa-info-circle fa-fw fa-3x"></i></div></a>
                <div class="circle-tile-content blue">
                  <div class="circle-tile-description text-faded"><h1>Consult</h1></div>
                  <div class="circle-tile-number text-faded "></div>
                  <a class="circle-tile-footer" href="#"><i class="fa fa-chevron-circle-right"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div  *ngIf="userStatus('Student')">
                       <div class="row">
            <div class="col-lg-3 col-md-8 h-100" >
              <div class="circle-tile ">
                <a routerLinkActive="active" routerLink="/learning"><div class="circle-tile-heading dark-blue"><i class="fa fa-book fa-fw fa-3x"></i></div></a>
                <div class="circle-tile-content dark-blue">
                  <div class="circle-tile-description text-faded"><h1>Learning</h1></div>
                  <div class="circle-tile-number text-faded "></div>
                  <a class="circle-tile-footer" href="#"><i class="fa fa-chevron-circle-right"></i></a>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-sm-8">
              <div class="circle-tile ">
                <a routerLinkActive="active" routerLink="/events"><div class="circle-tile-heading blue"><i class="fa fa-bullhorn fa-fw fa-3x"></i></div></a>
                <div class="circle-tile-content blue">
                  <div class="circle-tile-description text-faded"><h1>Events</h1></div>
                  <div class="circle-tile-number text-faded "></div>
                  <a class="circle-tile-footer" href="#"><i class="fa fa-chevron-circle-right"></i></a>
                </div>
              </div>
            </div>

          <div class="col-lg-3 col-sm-8">
              <div class="circle-tile ">
                <a routerLinkActive="active" routerLink="/resources"><div class="circle-tile-heading green"><i class="fa fa-briefcase fa-fw fa-3x"></i></div></a>
                <div class="circle-tile-content green">
                  <div class="circle-tile-description text-faded"><h1>Resources</h1></div>
                  <div class="circle-tile-number text-faded "></div>
                  <a class="circle-tile-footer" href="#"><i class="fa fa-chevron-circle-right"></i></a>
                </div>
              </div>
            </div>

          <div class="col-lg-3 col-sm-8">
              <div class="circle-tile ">
                <a routerLinkActive="active" routerLink="/profile"><div class="circle-tile-heading orange"><i class="fa fa-user fa-fw fa-3x"></i></div></a>
                <div class="circle-tile-content orange">
                  <div class="circle-tile-description text-faded"><h1>Profile</h1></div>
                  <div class="circle-tile-number text-faded "></div>
                  <a class="circle-tile-footer" href="#"><i class="fa fa-chevron-circle-right"></i></a>
                </div>
              </div>
            </div>

          </div>



        </div>
        <!-------------------------------------------------------------------------------------------------------------->
        </main>

      </div>
    </main>
  </div>

</div>
