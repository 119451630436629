<nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">


  <div class="position-sticky pt-3"   *ngIf="userStatus('Admin')">
    <ul class="nav flex-column">
      <li class="nav-item">
        <a class="nav-link " routerLinkActive="active" routerLink="/home">
          <i class="fas fa-home"></i>
          Dashboard
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link " routerLinkActive="active" routerLink="/reports">
          <i class="fas fa-book"></i>
          Reports
        </a>
      </li>
     

      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="/activeCon">
          <i class="fas fa-cloud"></i>
          Active Connections
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="/siteupdates">
          <i class="fas fa-flag-checkered"></i>
          DA Reports
        </a>
      </li>

    </ul>
  </div>

      <div class="position-sticky pt-3"   *ngIf="userStatus('Stuff')">
        <ul class="nav flex-column">

          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" routerLink="/lms">
              <i class="fas fa-book"></i>
              IoT Fundza Admin
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link " routerLinkActive="active" routerLink="/enquiries">
              <i class="fas fa-envelope"></i>
              Enquiries Inbox
            </a>
          </li>

        </ul>
      </div>


      <div class="position-sticky pt-3"   *ngIf="userStatus('Student')">
        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link " routerLinkActive="active" routerLink="/home">
              <i class="fas fa-home"></i>
              Home
            </a>
          </li>
         
          <li class="nav-item">
            <a class="nav-link " routerLinkActive="active" routerLink="/enquiries">
              <i class="fas fa-envelope"></i>
              Log Query
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" routerLink="/lms">
              <i class="fas fa-book"></i>
              Learning Management System
            </a>
          </li>
        </ul>
      </div>

      <div class="position-sticky pt-3"   *ngIf="userStatus('User')">
        <ul class="nav flex-column"> 
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" routerLink="/siteupdates">
              <i class="fas fa-flag-checkered"></i>
              Send Report/ Request Support
            </a>
          </li>
    
        </ul>
      </div>

      <ul class="nav flex-column">
        <li class="nav-item text-nowrap">
          <a class="nav-link" (click)="onLogout()"  href="#">
            <i class="fas fa-sign-out-alt"></i>
            Sign out
          </a>
        </li>
      </ul>

    </nav>

