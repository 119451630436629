import { OthercoursesComponent } from './dashboard/othercourses/othercourses.component';
import { LmsComponent } from './dashboard/lms/lms.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';
import {HomeComponent} from './dashboard/home/home.component';
import {LoginComponent} from './login/login.component';
import {ReportsComponent} from './dashboard/reports/reports.component';
import {EnquiriesComponent} from './dashboard/enquiries/enquiries.component' ;
import {ActiveConComponent} from './dashboard/active-con/active-con.component' ;
import {CoursesComponent} from './dashboard/courses/courses.component' ;
import {AssignmentsComponent} from './dashboard/assignments/assignments.component' ;
import {ResourcesComponent} from './dashboard/resources/resources.component' ;
import {AddcourseComponent} from './dashboard/addcourse/addcourse.component' ;
import {AddresourceComponent} from './dashboard/addresource/addresource.component' ;
import {ProfileComponent} from './dashboard/profile/profile.component' ;
import {EventsComponent} from './dashboard/events/events.component' ;
import {LearningComponent} from './dashboard/learning/learning.component' ;
import {CourseComponent} from './dashboard/course/course.component' ;
import {RegisterationComponent} from './registeration/registeration.component' ;
import {GeneralReportComponent} from './general-report/general-report.component' ;
import {MicrosoftlearnComponent} from './dashboard/microsoftlearn/microsoftlearn.component' ;
import {CatelogproductsComponent} from './dashboard/catelogproducts/catelogproducts.component' ;
import {SiteupdatesComponent} from './dashboard/siteupdates/siteupdates.component' ;



const routes: Routes = [{path: '', redirectTo: '/home', pathMatch: 'full' },
{ path: 'home', component: HomeComponent,canActivate:[AuthGuard]},
{ path: 'reports', component: ReportsComponent,canActivate:[AuthGuard]},
{ path: 'enquiries', component: EnquiriesComponent,canActivate:[AuthGuard]},
{ path: 'activeCon', component: ActiveConComponent,canActivate:[AuthGuard]},
{ path: 'lms', component: LmsComponent,canActivate:[AuthGuard]},
{ path: 'courses', component: CoursesComponent,canActivate:[AuthGuard]},
{ path: 'assignments', component: AssignmentsComponent,canActivate:[AuthGuard]},
{ path: 'resources', component: ResourcesComponent,canActivate:[AuthGuard]},
{ path: 'profile', component: ProfileComponent,canActivate:[AuthGuard]},
{ path: 'events', component: EventsComponent,canActivate:[AuthGuard]},
{ path: 'addcourse', component: AddcourseComponent,canActivate:[AuthGuard]},
{ path: 'addresource', component: AddresourceComponent,canActivate:[AuthGuard]},
{ path: 'learning', component:LearningComponent ,canActivate:[AuthGuard]},
{ path: 'course', component:CourseComponent ,canActivate:[AuthGuard]},
{ path: 'otherCourses', component:OthercoursesComponent ,canActivate:[AuthGuard]},
{ path: 'microsoftLearn', component:MicrosoftlearnComponent ,canActivate:[AuthGuard]},
{ path: 'catelogProducts', component:CatelogproductsComponent ,canActivate:[AuthGuard]},
{ path: 'siteupdates', component:SiteupdatesComponent ,canActivate:[AuthGuard]},
/*{ path: 'registeration', component:RegisterationComponent},
{ path: 'generalReport', component:GeneralReportComponent},*/
{path:'login',component:LoginComponent}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
