import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Chart } from 'chart.js';
import 'rxjs/add/operator/map';
import { DataService } from '../services/data.service' ;

@Component({
  selector: 'app-general-report',
  templateUrl: './general-report.component.html',
  styleUrls: ['./general-report.component.css']
})
export class GeneralReportComponent implements OnInit {

  constructor(private httpClient: HttpClient, private service: DataService) { }
  RegionReading = [];
  GenderReadings = [];
  DataUsageByAge = [];

  ngOnInit(): void {

    this.service.HotSpots().subscribe(
      res => {
        let xAxis = res.map(res => res.name);
        let dataUsage = res.map(res => res.data);
        let yAxis: number[];
        var color = Chart.helpers.color;
        var barChartData = {
          labels: xAxis,
          datasets: [{
            label: 'Total Data',
            backgroundColor: color('rgba(30, 139, 195, 1)').alpha(0.5).rgbString(),
            borderColor: 'rgba(30, 139, 195, 1)',
            borderWidth: 1,
            data: dataUsage
          }]

        };

        this.RegionReading = new Chart('chartRegion', {
          type: 'bar',
          data: barChartData,
          options: {
            responsive: true,
            legend: {
              position: 'top',
            },
            title: {
              display: true,
            },
            scales: {
              xAxes: [{
                display: true,

                    barThickness : 95,
                scaleLabel: {
                  display: true,
                  labelString: 'Hotspot'
                }
              }],
              yAxes: [{
                categoryPercentage: 1,
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: 'MegaByte'
                },
                ticks: {
                  beginAtZero: true
                }

              }]
            }

          }
        })
      },
      err => {
        console.log(err);
      },
    );

    this.service.DataUsagePerGender().subscribe(
      res => {
        let MaledataUsage = res[0];
        let FemaledataUsage = res[1];

        //Gender Data Usage
        this.GenderReadings = new Chart('chartGender', {
          type: 'pie',
          data: {
            datasets: [{
              data: [MaledataUsage,FemaledataUsage],
              backgroundColor: [
                'rgba(44, 130, 201, 1)',
                'rgba(42, 60, 125, 1)',
              ],
            }],
            labels: [
              'Male',
              'Female',
            ]
          },
          options: {
            responsive: true,
            title: {
              display: true,
              text: 'Data Usage By Gender'
            }
          }
        });

        this.service.DataUsageByAge().subscribe(
          res => {
            //{'Below 12','12 - 17','18 - 25','26 - 31','Above 31'}
            let ageGroupA = res[0];
            let ageGroupB = res[1];
            let ageGroupC = res[2];
            let ageGroupD = res[3];
            let ageGroupE = res[4];

            //MostSearchedCategories
            this.DataUsageByAge = new Chart('chartCategory', {
              type: 'doughnut',
              data: {
                datasets: [{
                  data: [ageGroupA, ageGroupB, ageGroupC, ageGroupD, ageGroupE],
                  backgroundColor: [
                    'rgba(240, 52, 52, 1)',
                    'rgba(248, 148, 6, 1)',
                    'rgba(244, 247, 118, 1)',
                    'rgba(42, 187, 155, 1)',
                    'rgba(44, 130, 201, 1)',
                  ],
                  label: 'Dataset 1'
                }],
                labels: [
                  'Below 12',
                  '12 - 17',
                  '18 - 25',
                  '26 - 31',
                  'Above 31',
                ],
              },
              options:
              {
                responsive: true,
                legend: {
                  position: 'top',
                },
                title: {
                  display: true,
                  text: 'Internet Usage Per Age Group'
                },
                animation: {
                  animateScale: true,
                  animateRotate: true
                },
              }
            });
          },
          err => {
            console.log(err);
          },
        );



      },
      err => {
        console.log(err);
      },
    );



  }

}
