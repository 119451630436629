import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service' ;
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-enquiries',
  templateUrl: './enquiries.component.html',
  styleUrls: ['./enquiries.component.css']
})
export class EnquiriesComponent implements OnInit {

  queries = [];

  constructor(private httpClient: HttpClient,private toastr: ToastrService, private service: DataService,public router: Router) { }

  ngOnInit(): void {
    this.service.Queries().subscribe(
      res => {
        console.log(res);
        this.queries = res;
      },
      err => {
        console.log(err);
      },
    );
  }

  userStatus(user: string): any {
    return this.service.SearchRole(user);
  }



}
