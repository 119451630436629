import { HttpClient, HttpHeaders,HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/Rx';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import {Observable} from 'rxjs/Rx';


@Injectable({
  providedIn: 'root'
})
export class DataService {
  //apiUrl ="https://dev.braceinnovations.co.za/api/";
  apiUrl = "http://18.132.98.36:81/api/" ;


  //SelectedCourse;
 // SelectedProduct;

  private courseSource = new BehaviorSubject('default course');
  courseMessage = this.courseSource.asObservable();

  private productSource = new BehaviorSubject('default product');
  productMessage = this.courseSource.asObservable();

  constructor(private fb: FormBuilder, private httpClient: HttpClient) { }

  courseform = this.fb.group({
    CourseId: ['', Validators.required],
    CourseName: ['', Validators.required],
    Description: ['', Validators.required],
  });


  addCourse() {
    var body = {
      CourseId: this.courseform.value.CourseId,
      CourseName: this.courseform.value.CourseName,
      Description: this.courseform.value.CourseName,
    };
    return this.httpClient.post(this.apiUrl + "Data/AddCourse", body);
  }


  resourceform = this.fb.group({
    courseCode: ['', Validators.required],
    resourceCode: ['', Validators.required],
    description: ['', Validators.required],
    file: ['', Validators.required],
    fileSource: ['', Validators.required],
  });


  addResource(file: FormData) {
    var courseNo = this.resourceform.value.courseCode;
    var resourceCode = this.resourceform.value.resourceCode;
    var description = this.resourceform.value.description;


    var headerOptions = new HttpHeaders();
    headerOptions.append('Content-Type', 'application/json');
    /*headerOptions.append('Access-Control-Allow-Headers',"*");
    headerOptions.append('Access-Control-Allow-Methods', 'POST');
    headerOptions.append('Access-Control-Allow-Origin', '*');*/

    //let headers = new Headers({ 'Content-Type': 'application/json' });

    /*const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      ///.append('Access-Control-Allow-Headers',"*")
      .append('Access-Control-Allow-Methods', 'POST')
      //.append('Access-Control-Allow-Origin', '*');*/

      /*let fromObject = {
        courseNo : this.resourceform.value.courseCode,
        resourceCode : this.resourceform.value.resourceCode,
        description : this.resourceform.value.description,
      }

      const params = new HttpParams({
        fromObject: fromObject
      });*/

      //return this.httpClient.post(this.apiUrl+"File/Upload",{params: params},{headers});
      return this.httpClient.post(this.apiUrl + "File/Upload?courseCode=" + courseNo + "&resourceCode=" + resourceCode + "&description=" + description, file,{headers: headerOptions});
  }


  Registerform = this.fb.group({
    UserName: ['', Validators.required],
    FullName: ['', Validators.required],
    Email: ['', Validators.required],
    PhoneNumber: ['', Validators.required],
    IdNumber: ['', Validators.required],
    Gender: ['', Validators.required],
    Race: ['', Validators.required],
    City: ['', Validators.required],
    Province: ['', Validators.required],
    routerName: ['', Validators.required],
  });

  Register() {
    var body = {
      UserName: this.Registerform.value.UserName,
      FullName: this.Registerform.value.FullName,
      Email: this.Registerform.value.Email,
      PhoneNumber: this.Registerform.value.PhoneNumber,
      IdNumber: this.Registerform.value.IdNumber,
      Gender: this.Registerform.value.Gender,
      Role: 'User',
      Race: this.Registerform.value.Race,
      City: this.Registerform.value.City,
      Province: this.Registerform.value.Province,
      routerName: this.Registerform.value.routerName,
    };
    return this.httpClient.post(this.apiUrl + "User/AddRouterUser", body);
  }


  enquiryform = this.fb.group({
    subject: ['', Validators.required],
    description: ['', Validators.required],
  });

  logEnquiry() {
    var body = {
      subject: this.enquiryform.value.subject,
      description: this.enquiryform.value.description,
      loggedBy: 'TEST',

    };
    return this.httpClient.post(this.apiUrl + "Data/logQuery", body);
  }

  sendReportform = this.fb.group({
    date : ['', Validators.required],
    nameofHotspot : ['', Validators.required],
    issueDiscription : ['', Validators.required],
    challengeIdentified : ['', Validators.required],
    complaintOwner : ['', Validators.required],
    proposedResolution : ['', Validators.required],
    dateResolved : ['', Validators.required],
    dateClosed : ['', Validators.required],
    satisfiedwithProcess : ['', Validators.required], 
    notSatisfiedwithProcess : [''], 
   satisfiedwithOutcome : ['', Validators.required],
   notSatisfiedwithOutcome : [''],
   comments : ['', Validators.required],
    da: [''],
  });

  sendReport(user : any) {
  
    var body = {
      date: this.sendReportform.value.date,
      nameofHotspot: this.sendReportform.value.nameofHotspot,
      issueDiscription: this.sendReportform.value.issueDiscription,
      challengeIdentified: this.sendReportform.value.challengeIdentified,
      complaintOwner: this.sendReportform.value.complaintOwner,
      proposedResolution: this.sendReportform.value.proposedResolution,
      dateResolved: this.sendReportform.value.dateResolved,
      dateClosed: this.sendReportform.value.dateClosed,
      satisfiedwithProcess: this.sendReportform.value.satisfiedwithProcess,
      notSatisfiedwithProcess: this.sendReportform.value.notSatisfiedwithProcess,
      satisfiedwithOutcome: this.sendReportform.value.satisfiedwithOutcome,
      notSatisfiedwithOutcome: this.sendReportform.value.notSatisfiedwithOutcome,
      comments: this.sendReportform.value.comments,
      da: user,

    };
    return this.httpClient.post(this.apiUrl + "Reports/AddReport", body);
  }

  SearchRole(role: string): boolean {
    var isMatch = false;
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    var userRole = payLoad.role;
    if (userRole === role) {
      isMatch = true;
    }
    return isMatch;
  }


  changeCourse(course: string) {
    this.courseSource.next(course);
  }

  changeProduct(product: string) {
    this.courseSource.next(product);
  }


  Profile(id: string): any {
    return this.httpClient.get<any>(this.apiUrl + "user/profile?userId=" + id);
  }
  
  TotalRouterDataUsage(){
    return this.httpClient.get<any>(this.apiUrl + "Data/TotalRouterDataUsage");
  }

  Top10Users(){
    return this.httpClient.get<any>(this.apiUrl + "Data/Top10Users");
  }

  RouterDataUsage(){
    return this.httpClient.get<any>(this.apiUrl + "Data/RouterDataUsage");
  }

  TotalOnlineUsers() {
    return this.httpClient.get<any>(this.apiUrl + "Data/totalOnlineUsers");
  }

  TotalUsers() {
    return this.httpClient.get<any>(this.apiUrl + "Data/totalUsers");
  }
  ActiveUsers() {
    return this.httpClient.get<any>(this.apiUrl + "Data/ActiveUsers");
  }

  HotSpots() {
    return this.httpClient.get<any>(this.apiUrl + "Data/RouterDataUsage");
  }

  Users() {
    return this.httpClient.get<any>(this.apiUrl + "Data/Users");
  }

  MonthlyUsage() {
    return this.httpClient.get<any>(this.apiUrl + "Data/DataUsageMonthlyReport");
  }


  DataUsagePerGender() {
    return this.httpClient.get<any>(this.apiUrl + "Data/DataUsagePerGender");
  }

  DataUsageByAge() {
    return this.httpClient.get<any>(this.apiUrl + "Data/DataUsageByAge");
  }

  Queries() {
    return this.httpClient.get<any>(this.apiUrl + "Data/Queries");
  }

  GetLMSProperties(input: string) {
    return this.httpClient.get<any>(this.apiUrl + "Data/GetLMSProperties?queryCode=" + input);
  }

  GetCourseResources(input: string) {
    if (input != 'default course') {
      return this.httpClient.get<any>(this.apiUrl + "Data/GetCourseResources?courseCode=" + input);
    }
  }

  DownloadResource(filename: string) {
    var headerOptions = new HttpHeaders();
    headerOptions.append('Content-Type', 'application/json');
    headerOptions.append('Access-Control-Allow-Headers',"*");
    headerOptions.append('Access-Control-Allow-Methods', 'POST');
    headerOptions.append('Access-Control-Allow-Origin', '*');

    return this.httpClient.get<Blob>(this.apiUrl + "File/DownloadFile?fileName=" + filename, { responseType: 'blob' as 'json',headers: headerOptions });
  }

  Routers() {
    return this.httpClient.get<any>(this.apiUrl + "Data/GetRouters");
  }

  Genders() {
    return this.httpClient.get<any>(this.apiUrl + "Data/GetGender");
  }

  Races() {
    return this.httpClient.get<any>(this.apiUrl + "Data/GetRace");
  }

  Provinces() {
    return this.httpClient.get<any>(this.apiUrl + "Data/GetProvince");
  }

  MicrosoftLearnCatalog(input: string) {
    return this.httpClient.get<any>(this.apiUrl + "Data/MicrosoftCatalog?module="+input);
  }

  MicrosoftLearnCatalogProducts() {
    return this.httpClient.get<any>(this.apiUrl + "Data/MicrosoftCatalogProducts");
  }

  
  GetReports() {
    return this.httpClient.get<any>(this.apiUrl + "Reports/GetReports");
  }

  GetHotspotNames(){
    return this.httpClient.get<any>(this.apiUrl + "Hotspots/GetHotspots");
  }

  ExtractReports(): Observable<Blob>
     {
         var url = this.apiUrl + "Reports/DAReports";
         var authorization = 'Bearer '+sessionStorage.getItem("access_token");

         const headers = new HttpHeaders({ 'Content-Type': 'application/json',
         "Authorization": authorization, responseType : 'blob'});

         return this.httpClient.get<Blob>(url, { headers : headers,responseType : 
         'blob' as 'json'});
      
     }

  
  
  

}
