import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { ReportsComponent } from './reports/reports.component';
import { EnquiriesComponent } from './enquiries/enquiries.component';
import { ActiveConComponent } from './active-con/active-con.component';
import { LmsComponent } from './lms/lms.component';
import { CoursesComponent } from './courses/courses.component';
import { AssignmentsComponent } from './assignments/assignments.component';
import { ResourcesComponent } from './resources/resources.component';
import { AddcourseComponent } from './addcourse/addcourse.component';
import { AddassignmentComponent } from './addassignment/addassignment.component';
import { AddresourceComponent } from './addresource/addresource.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserHomeComponent } from './user-home/user-home.component';
import { ProfileComponent } from './profile/profile.component';
import { EventsComponent } from './events/events.component';
import { LogqueryComponent } from './logquery/logquery.component';
import { LearningComponent } from './learning/learning.component';
import { CourseComponent } from './course/course.component';
import { OthercoursesComponent } from './othercourses/othercourses.component';
import { MicrosoftlearnComponent } from './microsoftlearn/microsoftlearn.component';
import { CatelogproductsComponent } from './catelogproducts/catelogproducts.component';
import { SiteupdatesComponent } from './siteupdates/siteupdates.component';


@NgModule({
  declarations: [NavbarComponent, HomeComponent, FooterComponent, HeaderComponent, ReportsComponent, EnquiriesComponent, ActiveConComponent, LmsComponent, CoursesComponent, AssignmentsComponent, ResourcesComponent, AddcourseComponent, AddassignmentComponent, AddresourceComponent, UserHomeComponent, ProfileComponent, EventsComponent, LogqueryComponent, LearningComponent, CourseComponent, OthercoursesComponent, MicrosoftlearnComponent, CatelogproductsComponent, SiteupdatesComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class DashboardModule { }
