<form  style="color: white;" [formGroup]="service.enquiryform" autocomplete="off" (submit)="onSubmit()">
  <div class="row">

    <div class="form-group col-md-12">
      <label>Subject</label>
      <input class="form-control" formControlName="subject">
      <label class="text-danger"
        *ngIf="service.enquiryform.get('subject').touched && service.enquiryform.get('subject').errors?.required">This
        field is mandatory.</label>
    </div>

    <div class="form-group col-md-12">
      <label>Description</label>
      <textarea  rows="6" class="form-control" formControlName="description"></textarea>
      <label class="text-danger"
        *ngIf="service.enquiryform.get('description').touched && service.enquiryform.get('description').errors?.required">This
        field is mandatory.</label>
    </div>
  </div>
   <hr>
  <div class="text-center">
    <div class="form-group col-md-8 offset-md-2">

    <button type="button" class="btn btn-lg btn-danger" style="margin:10px;" (click)="router.navigate(['/home']);" >Cancel</button>
    <button type="submit" class="btn btn-lg btn-primary" [disabled]="!service.enquiryform.valid">Log Query</button>

    </div>
  </div>
</form>
