<app-header></app-header>

<div class="container-fluid">
  <div class="row">
    <app-navbar></app-navbar>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="container">
        <main role="main" class="pb-3 ">
        <!-------------------------------------------------------------------------------------------------------------->

        <section class="section services-section" id="services">
          <div class="container">
              <div class="row">

                  <div class="col-sm-6 col-lg-4"  *ngFor="let info of modules">

                      <div class="feature-box-1" routerLinkActive="active" (click)= "courseSelected(info.url)" >
                        <img  class="icon" src={{info.icon_url}} alt="Avatar" class="image">
                          <div class="feature-content">
                              <p>{{info.title}}</p>
                              <h6 style="font-size:17px; color:blueviolet;">Course Level : {{info.levels}}</h6>
                              <!--<button class="btn"><i class="fa fa-graduation-cap"  ></i> Enroll Course</button>-->
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>

        <!-------------------------------------------------------------------------------------------------------------->
        </main>

      </div>
    </main>
  </div>

</div>
