<app-header></app-header>

<div class="container-fluid">
  <div class="row">
    <app-navbar></app-navbar>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="container">
        <main role="main" class="pb-3">
        <!-------------------------------------------------------------------------------------------------------------->
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 class="h2" >Dashboard</h1>
          <div class="btn-toolbar mb-2 mb-md-0" >
              <div class="btn-group me-2">
                  <button type="button" class="btn btn-sm btn-outline-secondary" >Export / Download</button>
              </div>
          </div>
        </div>

        <!--<canvas class="my-4 w-100" id="chart" width="900" height="380"></canvas>-->

        <h2 class="text-center">Hotspots Operational Details </h2>
        <div class="table-responsive">
          <table class="table table-bordered table-striped table-sm">
            <thead>
              <tr>
                <th>Router Name</th>
                <th>Router IP</th>
                <th>Last DownTime</th>
                <th>Last UpTime</th>
                <th>Data Usage</th>
                <th>Link Downs</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let info of routers"  s>
                <td>{{ info.routerName }}</td>
                <td>{{ info.routerIP }}</td>
                <td>{{ info.lastDownTime}}</td>
                <td>{{ info.lastUpTime }}</td>
                <td>{{ info.dataUsage }}</td>
                <td>{{ info.linkDowns }}</td>
                <!--<td><a ><i class="fas fa-eye"></i></a></td>-->
             </tr>
            </tbody>
          </table>
        </div>

        <div style="width:95%;">
          <canvas id="chartRegion"></canvas>
        </div>

        <h2 class="text-center">Top 10 Regular Users</h2>
        <div class="table-responsive">
          <table class="table table-bordered table-striped table-sm">
            <thead>
              <tr>
                <th>Username</th>
                <th>Up Time</th>
                <th>Upload</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let info of topUsers"  s>
                <td>{{ info.username }}</td>
                <td>{{ info.upTime }}</td>
                <td>{{ info.upload}} GB</td>
                <td>{{ info.download }} GB</td>
                <!--<td><a ><i class="fas fa-eye"></i></a></td>-->
             </tr>
            </tbody>
          </table>
        </div>


        <!-------------------------------------------------------------------------------------------------------------->
        </main>

      </div>
    </main>
  </div>

</div>
