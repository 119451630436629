<app-header></app-header>

<div class="container-fluid">
  <div class="row">
    <app-navbar></app-navbar>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="container">
        <main role="main" class="pb-3 ">
          <!-------------------------------------------------------------------------------------------------------------->

          <div class="visibilityDiv text-center">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-border text-secondary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-border text-success" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-border text-danger" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-border text-warning" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-border text-info" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-border text-light" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-border text-dark" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <section class="section services-section" id="services">
            <div class="container">
              <div class="row">

                <div class="col-sm-6 col-lg-4" *ngFor="let info of products">

                  <div class="feature-box-1" routerLinkActive="active" (click)="productSelected(info.id)">
                    <div class="feature-content">
                      <h1>{{info.name}}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!-------------------------------------------------------------------------------------------------------------->
        </main>

      </div>
    </main>
  </div>

</div>