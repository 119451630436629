<div *ngIf="userStatus('Student')">
<section class="section about-section gray-bg" id="about">
  <div class="container">
      <div class="row align-items-center flex-row-reverse">
          <div class="col-lg-6">
              <div class="about-text go-to">
                  <h3 class="dark-color">About Me</h3>
                  <h6 class="theme-color lead">Learning Management System Profile</h6>
                  <p>This your personal details  that is recorded on the system if there's anything wrong or anything you want
                    to change please contact the System Administrator or simply log a call.
                  </p>
                  <div class="row about-list">
                      <div class="col-md-6">
                          <div class="media">
                              <label>Full Name</label>
                              <p>{{profile.fullName}}</p>
                          </div>
                          <div class="media">
                              <label>ID Number</label>
                              <p>{{profile.idNumber}}</p>
                          </div>
                          <div class="media">
                              <label>Gender</label>
                              <p>{{profile.gender}}</p>
                          </div>
                      </div>
                      <div class="col-md-6">
                          <div class="media">
                              <label>E-mail</label>
                              <p>{{profile.email}}</p>
                          </div>
                          <div class="media">
                              <label>Phone</label>
                              <p>{{profile.phoneNumber}}</p>
                          </div>
                          <div class="media">
                              <label>Age</label>
                              <p>{{getAge(profile.idNumber)}}</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-lg-6">
              <div class="about-avatar">
                  <img src="../../../assets/img/student.png" title="" alt="">
              </div>
          </div>
      </div>
  </div>
</section>
</div>

<div  *ngIf="userStatus('User')">

    <section class="section about-section gray-bg" id="about" >
        <div class="container">
            <div class="row align-items-center flex-row-reverse">
                <div class="col-lg-6">
                    <div class="about-text go-to">
                        <h3 class="dark-color">About Me</h3>
                        <h6 class="theme-color lead">Digital Ambassador</h6>
                        <p>
                        </p>
                        <div class="row about-list">
                            <div class="col-md-6">
                                <div class="media">
                                    <label>Full Name</label>
                                    <p>{{profile.fullName}}</p>
                                </div>
                                <div class="media">
                                    <label>ID Number</label>
                                    <p>{{profile.idNumber}}</p>
                                </div>
                                <div class="media">
                                    <label>Gender</label>
                                    <p>{{profile.gender}}</p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="media">
                                    <label>E-mail</label>
                                    <p>{{profile.email}}</p>
                                </div>
                                <div class="media">
                                    <label>Phone</label>
                                    <p>{{profile.phoneNumber}}</p>
                                </div>
                                <div class="media">
                                    <label>Age</label>
                                    <p>{{getAge(profile.idNumber)}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="about-avatar">
                        <img src="../../../assets/img/student.png" title="" alt="">
                    </div>
                </div>
            </div>
        </div>
      </section> 

</div>
