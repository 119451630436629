import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service' ;

@Component({
  selector: 'app-lms',
  templateUrl: './lms.component.html',
  styleUrls: ['./lms.component.css']
})
export class LmsComponent implements OnInit {

  constructor( private service: DataService) { }

  ngOnInit(): void {
  }

  userStatus(user: string): any {
    return this.service.SearchRole(user);
  }

}
