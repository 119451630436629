<header class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
  <a *ngIf="userStatus('Admin')" class="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#">IoT - Data Mining</a>
  <a *ngIf="userStatus('Stuff')" class="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#">IoT - Fundza Portal</a>
  <a *ngIf="userStatus('Student')" class="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#">IoT - Fundza Portal</a>
  <a *ngIf="userStatus('User')" class="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#">IoT - DA Portal</a>
  <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse"
    data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
</header>
