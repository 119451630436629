import { Component, OnInit } from '@angular/core';
import {Chart} from 'chart.js' ;
import { DataService } from '../../services/data.service'

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  chartadata:[];
  RegionReading = [];
  topUsers = [];
  routers = [];

  constructor(private service : DataService) { }

  ngOnInit(): void {
     
    this.service.RouterDataUsage().subscribe(
      res => {
        //console.log(res);
        this.routers = res;
      },
      err => {
        console.log(err);
      },
    );

    this.service.Top10Users().subscribe(
      res => {
        //console.log(res);
        this.topUsers = res;
      },
      err => {
        console.log(err);
      },
    );


    this.service.HotSpots().subscribe(
      res => {
        let xAxis = res.map(res => res.routerName);
        let dataUsage = res.map(res => res.dataUsage);
        let yAxis: number[];
        var color = Chart.helpers.color;
        var barChartData = {
          labels: xAxis,
          datasets: [{
            label: 'Total Data',
            backgroundColor: color('rgba(30, 139, 195, 1)').alpha(0.5).rgbString(),
            borderColor: 'rgba(30, 139, 195, 1)',
            borderWidth: 1,
            data: dataUsage
          }]

        };

        this.RegionReading = new Chart('chartRegion', {
          type: 'bar',
          data: barChartData,
          options: {
            responsive: true,
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Data Usage Per Hotspot',
            },
            scales: {
              xAxes: [{
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: 'Hotspot'
                }
              }],
              yAxes: [{
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: 'GigaByte'
                },
                ticks: {
                  beginAtZero: true
                }

              }]
            }

          }
        })
      },
      err => {
        console.log(err);
      },
    );



    this.service.MonthlyUsage().subscribe(
      res => {
        let xAxis = res.map(res => res.month);
        let dataUsage = res.map(res => res.data);
        let yAxis : number [] ;


            //Charts
    var color = Chart.helpers.color;
    var barChartData = {
      labels: xAxis,
      datasets: [{
        label: 'Total Data',
        backgroundColor: color('rgba(30, 139, 195, 1)').alpha(0.5).rgbString(),
        borderColor: 'rgba(30, 139, 195, 1)',
        borderWidth: 1,
        data: dataUsage
      }]

    };

    this.chartadata = new Chart('chart', {
      type: 'bar',
      data: barChartData,
      options: {
        responsive: true,
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Data Usage Monthly Report'
        },
        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Month'
            }
          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'GigaByte'
            },
            ticks: {
              beginAtZero: true
            }

          }]
        }

      }
    })


      },
      err => {
        console.log(err);
      },
    );

  }

}
