import { Component, OnInit } from '@angular/core';
import {DataService} from '../../services/data.service'
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-active-con',
  templateUrl: './active-con.component.html',
  styleUrls: ['./active-con.component.css']
})
export class ActiveConComponent implements OnInit {

  constructor(private service : DataService) { }

  activeusers = [] ;

  ngOnInit(): void {

    this.service.ActiveUsers().subscribe(
      res => {
        console.log(res);
        this.activeusers = res ;

        this.activeusers.forEach( function(data) {

          let dataConverted = data['bytes-out'] / 1048576;

          //data['bytesout'] = data['bytes-out'];
          data['bytesout'] = dataConverted;
          delete data['bytes-out'];
        });
      },
      err => {
        console.log(err);
      },
    );

  }

}
