<app-header></app-header>

<div class="container-fluid">
  <div class="row">
    <app-navbar></app-navbar>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="container">
        <main role="main" class="pb-3 ">
        <!-------------------------------------------------------------------------------------------------------------->

        <section class="section about-section gray-bg" id="about">
          <div class="container">
              <div class="row align-items-center flex-row-reverse">
                  <div class="col-lg-6">
                      <div class="about-text go-to">
                          <h3 class="dark-color">Progress Report</h3>
                            <div class="card l-bg-green-dark">
                                <div class="card-statistic-3 p-4">
                                    <div class="card-icon card-icon-large"><i class="fas fa-ticket-alt"></i></div>
                                    <div class="mb-4">
                                        <h5 class="card-title mb-0"></h5>
                                    </div>
                                    <div class="row align-items-center mb-2 d-flex">
                                        <div class="col-8">
                                            <h2 class="d-flex align-items-center mb-0">
                                            </h2>
                                        </div>
                                        <div class="col-4 text-right">
                                            <span>{{progressPercentage([totalCourses],[30])}}%<i class="fa fa-arrow-up"></i></span>
                                        </div>
                                    </div>
                                    <div class="progress mt-1 " data-height="8" style="height: 8px;">
                                        <div class="progress-bar l-bg-orange" role="progressbar" data-width="25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" [style.width.%]=[bar]></div>
                                    </div>
                                </div>
                            </div>
                      </div>
                  </div>
                  <div class="col-lg-6">
                      <div class="about-avatar">
                          <img src="../../../assets/img/avatar.png" title="" alt="">
                      </div>
                  </div>
              </div>

              <div class="counter">
                  <div class="row">
                      <div class="col-6 col-lg-3">
                          <div class="count-data text-center">
                              <h6 class="count h2" data-to="500" data-speed="500">{{courses.length }}</h6>
                              <p class="m-0px font-w-600">Total Courses</p>
                          </div>
                      </div>
                      <div class="col-6 col-lg-3">
                          <div class="count-data text-center">
                              <h6 class="count h2" data-to="150" data-speed="150">150</h6>
                              <p class="m-0px font-w-600">Assessment Completed</p>
                          </div>
                      </div>
                      <div class="col-6 col-lg-3">
                          <div class="count-data text-center">
                              <h6 class="count h2" data-to="850" data-speed="850">850</h6>
                              <p class="m-0px font-w-600">Current Mark</p>
                          </div>
                      </div>
                      <div class="col-6 col-lg-3">
                          <div class="count-data text-center">
                              <h6 class="count h2" data-to="190" data-speed="190">190</h6>
                              <p class="m-0px font-w-600">Awards</p>
                          </div>
                      </div>
                  </div>
              </div>


          </div>
        </section>

        <!-------------------------------------------------------------------------------------------------------------->
        </main>

      </div>
    </main>
  </div>

</div>

