import { Component, OnInit,Output,EventEmitter } from '@angular/core';
import { from } from 'rxjs';
import { DataService } from '../../services/data.service' ;
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css'],
})
export class CourseComponent implements OnInit {

  constructor(private service: DataService) { }
  resources ;
  selectedCourse:string ;
  subscription: Subscription;
  StoredselectedCourse:string ;
  doc   ;


  ngOnInit(): void {
    this.subscription = this.service.courseMessage.subscribe(data => this.selectedCourse = data) ;

   if (this.selectedCourse != 'default course'){
    localStorage.setItem('course', this.selectedCourse);
        this.StoredselectedCourse = this.selectedCourse;
    }

    if (this.selectedCourse == 'default course'){
          this.StoredselectedCourse = localStorage.getItem('course');
      }

   this.LoadResources(this.StoredselectedCourse);
  }

  LoadResources(courseCode:string){
    this.service.GetCourseResources(courseCode).subscribe(
      res => {
        this.resources = res;
        console.log(res);
      },
      err => {
        console.log(err);
      },
    );
  }

  file(fname:string){
    var i = fname.indexOf(".");
    var l = fname.length;
    var extension = fname.substring(i+1,l);
    return extension ;
  }

  userStatus(user: string): any {
    return this.service.SearchRole(user);
  }

  Download(fileName:string){
    //this.service.DownloadResource(filename);
    var i = fileName.indexOf(".");
    var l = fileName.length;
    var extension = fileName.substring(i,l);
    this.doc = fileName.substring(i+1,l);

    //console.log(extension);
    this.service.DownloadResource(fileName)
        .subscribe(
            data => {
                  const downloadedFile = new Blob([data], { type:extension });
                  const a = document.createElement('a');
                  a.setAttribute('style', 'display:none;');
                  document.body.appendChild(a);
                  a.download = fileName;
                  a.href = URL.createObjectURL(downloadedFile);
                  a.target = '_blank';
                  a.click();
                  document.body.removeChild(a);
            },
            err => {
                alert("Server error while downloading file.");
            }
        );
  }


}
