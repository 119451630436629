import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service' ;
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registeration',
  templateUrl: './registeration.component.html',
  styleUrls: ['./registeration.component.css']
})
export class RegisterationComponent implements OnInit {

  hotspots ;
  genders ;
  races ;
  provinces ;


  constructor(public service: DataService,private toastr: ToastrService,public router: Router) { }

  ngOnInit(): void {
    this.service.Registerform.reset();

    this.service.Routers().subscribe(
      res => {
        this.hotspots = res;
        //console.log(res);
      },
      err => {
        console.log(err);
      },
    );

    this.service.Genders().subscribe(
      res => {
        this.genders = res;
        //console.log(res);
      },
      err => {
        console.log(err);
      },
    );

    this.service.Races().subscribe(
      res => {
        this.races = res;
        //console.log(res);
      },
      err => {
        console.log(err);
      },
    );

    this.service.Provinces().subscribe(
      res => {
        this.provinces = res;
        //console.log(res);
      },
      err => {
        console.log(err);
      },
    );


  }

  onSubmit() {
    this.service.Register().subscribe(
      (res: any) => {
        window.location.href = 'https://www.google.com/';
      },
      err => {
        if (err.status == 400)
          this.toastr.error('Registeration failed', 'Error');

      }
    );
  }

  login(){
    window.location.href = 'https://www.google.com/'
  }


}
