<app-header></app-header>

<div class="container-fluid">
  <div class="row">
    <app-navbar></app-navbar>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="container">
        <main role="main" class="pb-3">
        <!-------------------------------------------------------------------------------------------------------------->
        <div class=" align-items-center p-3 my-3 text-white bg-purple rounded shadow-sm">
          <div class="lh-1">
              <h2 class="text-center mb-0 text-white lh-1">IoT Fundza - HelpDesk</h2>
          </div>
      </div>

      <div *ngIf="userStatus('Stuff')">
      <div class="my-3 p-3 bg-white rounded shadow-sm">
          <h6 class="border-bottom pb-2 mb-0">Recent Problems</h6>
          <div  *ngFor="let info of queries" class="d-flex text-muted pt-3">
            <svg class="bd-placeholder-img flex-shrink-0 me-2 rounded" style="fill:#024A8D;" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 16 16">
              <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
            </svg>
              <p class="pb-3 mb-0 small lh-sm border-bottom">
                  <strong class="d-block text-gray-dark">{{ info.description }} logged by : {{ info.loggedBy }}</strong>
                  {{ info.subject }}
              </p>
          </div>
          <small class="d-block text-end mt-3">
              <a href="#">All Problems</a>
          </small>
      </div>
    </div>

    <div *ngIf="userStatus('Student')">
        <app-logquery></app-logquery>
    </div>
        <!-------------------------------------------------------------------------------------------------------------->
        </main>

      </div>
    </main>
  </div>

</div>
