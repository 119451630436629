import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service' ;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  courses = [] ;
  totalCourses : number ;
  bar : number ;
  finishedTasks : number ;


  constructor(private service: DataService) { }

  ngOnInit(): void {
    this.finishedTasks = 20 ;
    this.service.GetLMSProperties("c").subscribe(
      res => {
        this.courses = res;
        this.totalCourses = this.courses.length ;
      },
      err => {
        console.log(err);
      },
    );

  }

  progressPercentage(totCourse : number ,completeCourse : number){
    this.bar = (completeCourse / totCourse) * 100 ;
     return this.bar ;
  }

}
