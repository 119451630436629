<app-header></app-header>

<div class="container-fluid">
  <div class="row">
    <app-navbar></app-navbar>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="container">
        <main role="main" class="pb-3 ">
          <!-------------------------------------------------------------------------------------------------------------->

          <div class="text-center">
            <h1>No Assessments Yet</h1>
            <hr >
            <div class="form-group col-md-8 offset-md-2">
              
            </div>
            <hr>
            <button style="float: right;" type="submit" class="btn btn-lg btn-primary" >Start Course</button>
          </div>
          <!-------------------------------------------------------------------------------------------------------------->
        </main>

      </div>
    </main>
  </div>

</div>
