import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service' ;
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-othercourses',
  templateUrl: './othercourses.component.html',
  styleUrls: ['./othercourses.component.css']
})
export class OthercoursesComponent implements OnInit {

  constructor(private service: DataService,public router: Router) { }
  courses ;
  subscription: Subscription;

  ngOnInit(): void {
    this.service.GetLMSProperties("c").subscribe(
      res => {
        this.courses = res;
      },
      err => {
        console.log(err);
      },
    );
  }

  courseSelected(courseCode:string) {
    this.service.changeCourse(courseCode);
    this.router.navigateByUrl('/course');
  }

}
