import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import 'rxjs/add/operator/map';
import { DataService } from '../../services/data.service' ;


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  DataReadings = [];
  GenderReadings = [];
  MostSearchedCategories = [];
 

 
  totalUsers ;
  totalOnlineUsers ;
  totalRouterDataUsage ;

  constructor(private httpClient: HttpClient, private service: DataService) { }

  ngOnInit(): void {

    this.service.DataUsagePerGender().subscribe(
      res => {
        let MaledataUsage = res[0];
        let FemaledataUsage = res[1];

        //Gender Data Usage
        this.GenderReadings = new Chart('chartGender', {
          type: 'pie',
          data: {
            datasets: [{
              data: [MaledataUsage,FemaledataUsage],
              backgroundColor: [
                'rgba(44, 130, 201, 1)',
                'rgba(42, 60, 125, 1)',
              ],
            }],
            labels: [
              'Male',
              'Female',
            ]
          },
          options: {
            responsive: true,
            title: {
              display: true,
              text: 'Data Usage By Gender'
            }
          }
        });



      },
      err => {
        console.log(err);
      },
    );


    this.service.DataUsageByAge().subscribe(
      res => {
        //{'Below 12','12 - 17','18 - 25','26 - 31','Above 31'}
        let ageGroupA = res[0];
        let ageGroupB = res[1];
        let ageGroupC = res[2];
        let ageGroupD = res[3];
        let ageGroupE = res[4];

        //MostSearchedCategories
        this.MostSearchedCategories = new Chart('chartCategory', {
          type: 'doughnut',
          data: {
            datasets: [{
              data: [ageGroupA, ageGroupB, ageGroupC, ageGroupD, ageGroupE],
              backgroundColor: [
                'rgba(240, 52, 52, 1)',
                'rgba(248, 148, 6, 1)',
                'rgba(244, 247, 118, 1)',
                'rgba(42, 187, 155, 1)',
                'rgba(44, 130, 201, 1)',
              ],
              label: 'Dataset 1'
            }],
            labels: [
              'Below 12',
              '12 - 17',
              '18 - 25',
              '26 - 31',
              'Above 31',
            ],
          },
          options:
          {
            responsive: true,
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Internet Usage Per Age Group'
            },
            animation: {
              animateScale: true,
              animateRotate: true
            },
          }
        });
      },
      err => {
        console.log(err);
      },
    );




    this.service.TotalUsers().subscribe(
      res => {
        //console.log(res);
        this.totalUsers = res;
      },
      err => {
        console.log(err);
      },
    );


    this.service.TotalOnlineUsers().subscribe(
      res => {
        //console.log(res);
        this.totalOnlineUsers = res;
      },
      err => {
        console.log(err);
      },
    );

    this.service.TotalRouterDataUsage().subscribe(
      res => {
        //console.log(res);
        this.totalRouterDataUsage = res;
      },
      err => {
        console.log(err);
      },
    );

  }
  

  userStatus(user: string): any {
    return this.service.SearchRole(user);
  }

}

