import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service' ;
import { HttpClient,HttpHeaders } from '@angular/common/http';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.css']
})
export class UserHomeComponent implements OnInit {

  profile ;
  idNo ;

  constructor(private httpClient :HttpClient, private service: DataService
    ,private userService: UserService) { }

  ngOnInit(): void {
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    var userId = payLoad.UserID;

    this.service.Profile(userId).subscribe(
      res => {
        this.profile = res;
        //console.log(this.profile);
      },
      err => {
        console.log(err);
      },
    );
  }

  userStatus(user:string):any{
    return this.userService.SearchRole(user) ;
  }

  getAge(idNo:string){
    var year = idNo.substring(0,1);

    if ( year === "0"){
      year = "20"+idNo.substring(0,2);
    }
    else{
      year = "19"+idNo.substring(0,2);
    }
    var intYear = Number(year);
    var currentYear = new Date().getFullYear();
    var intcurrentYear = Number(currentYear);
    var age = intcurrentYear - intYear ;

    return age ;
  }

}
