<app-header></app-header>

<div class="container-fluid">
  <div class="row">
    <app-navbar></app-navbar>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="container">
        <main role="main" class="pb-3 ">
        <!-------------------------------------------------------------------------------------------------------------->

        <div class="table-responsive">
          <div class="table-wrapper">
              <div class="table-title">
                  <div class="row">
                      <div class="col-sm-8"><h2><b></b></h2></div>
                  </div>
              </div>

          </div>
      </div>

           <form  [formGroup]="service.courseform" autocomplete="off" (submit)="onSubmit()">
      <div class="row">

        <div class="form-group col-md-2">
          <label>Course Code</label>
          <input class="form-control" formControlName="CourseId">
          <label class="text-danger"
            *ngIf="service.courseform.get('CourseId').touched && service.courseform.get('CourseId').errors?.required">This
            field is mandatory.</label>
        </div>
        <div class="form-group col-md-3">
          <label>Course CourseName</label>
          <input class="form-control" formControlName="CourseName">
          <label class="text-danger"
            *ngIf="service.courseform.get('CourseName').touched && service.courseform.get('CourseName').errors?.required">This
            field is mandatory.</label>
        </div>

        <div class="form-group col-md-7">
          <label>Course Description</label>
          <input class="form-control" formControlName="Description">
          <label class="text-danger"
            *ngIf="service.courseform.get('Description').touched && service.courseform.get('Description').errors?.required">This
            field is mandatory.</label>
        </div>
      </div>
       <hr>
      <div class="text-center">
        <div class="form-group col-md-8 offset-md-2">

        <button type="button" class="btn btn-lg btn-danger" style="margin:10px;" (click)="router.navigate(['/courses']);" >Cancel</button>
        <button type="submit" class="btn btn-lg btn-primary" [disabled]="!service.courseform.valid">Add Course</button>

        </div>
      </div>
    </form>

        <!-------------------------------------------------------------------------------------------------------------->
    </main>

      </div>
    </main>
  </div>
</div>
