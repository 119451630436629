import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service' ;
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css']
})
export class ResourcesComponent implements OnInit {

  resources ;
  doc   ;

  constructor(private service: DataService) { }

  ngOnInit(): void {
    this.service.GetLMSProperties("r").subscribe(
      res =>  {
        this.resources = res;
        //console.log(this.resources);
      },
      err => {
        console.log(err);
      },
    );
  }

  file(fname:string){
    var i = fname.indexOf(".");
    var l = fname.length;
    var extension = fname.substring(i+1,l);
    return extension ;
  }

  userStatus(user: string): any {
    return this.service.SearchRole(user);
  }

  Download(fileName:string){
    //this.service.DownloadResource(filename);
    var i = fileName.indexOf(".");
    var l = fileName.length;
    var extension = fileName.substring(i,l);
    this.doc = fileName.substring(i+1,l);

    //console.log(extension);
    this.service.DownloadResource(fileName)
        .subscribe(
            data => {
                  const downloadedFile = new Blob([data], { type:extension });
                  const a = document.createElement('a');
                  a.setAttribute('style', 'display:none;');
                  document.body.appendChild(a);
                  a.download = fileName;
                  a.href = URL.createObjectURL(downloadedFile);
                  a.target = '_blank';
                  a.click();
                  document.body.removeChild(a);
            },
            err => {
                alert("Server error while downloading file.");
            }
        );
  }

}
